import 'core-js/actual';
import 'regenerator-runtime/runtime.js';
import { showMessage } from './helpers/messages';

// Check for and display messages from localStorage (this is checked on every page load)
// We can use localStorage to send messages from one page to another like this
(() => {
   // Grab the message from localStorage
   const getMessage = JSON.parse(localStorage.getItem('message'));

   // If it doesn't exist, do nothing
   if (!getMessage) return;

   // Else let's show that message
   showMessage(getMessage.type, getMessage.message);

   // Remove the message from localStorage after displaying it so if the user loads the page again, they don't see the same message over and over again
   localStorage.removeItem('message');
})();

const getRefParam = () => {
   const url = new URLSearchParams(window.location.search);
   const ref = url.get('ref');

   if (!ref) return;

   localStorage.setItem('ref', ref);
};

getRefParam();
